import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const OperationalUnderClientsList = ({ openModal, closeModal}) => {

    return (
        <Modal show={openModal} onHide={closeModal} backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>Oprational Under Clients List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered mt-4" cellPadding={3}>
                    <thead className='table-primary'>

                        <tr>
                            <th style={{ textAlign: 'center' }}>No</th>
                            <th>Client Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td align='center'>1</td>
                            <td>Client 1</td>
                        </tr>
                        <tr>
                            <td align='center'>2</td>
                            <td>Client 2</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OperationalUnderClientsList