import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userData: "",
    singleUserData: {}
}

export const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserData: (s, a) => {
            s.userData = a.payload;
        },
        setSingleUserData: (s, a) => {
            s.singleUserData = a.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserData, setSingleUserData } = userDataSlice.actions

export default userDataSlice.reducer