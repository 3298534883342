
import { useParams } from 'react-router-dom'

import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-hot-toast'
import { FaCross } from 'react-icons/fa'
import SideBar from '../../../components/sidebar/Sidebar';
import { useUpdateStatus } from '../Modal/ClientsAssign/hook';
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { TiDelete } from "react-icons/ti";




const OperationalUserDetails = () => {

  let data = useParams();

  // const location = useLocation()

  const { alluserList, doSubmitUrl } = useUpdateStatus()

  const { defaultData } = useUpdateStatus(data.id);

  const [testData, setTestData] = useState("");
  const [list, setList] = useState([]);

  const handleTodoList = (e) => {
    e.preventDefault();

    if (testData === "") {
      toast("Please select client", {
        duration: 2000,
        icon: <FaCross />,
        style: {
          backgroundColor: "#ffabcd",
          fontSize: 14,
        },
      });
    } else {
      const data = alluserList.filter((x) => x._id === testData)

      setList(oldArray => [...oldArray, ...data]);

      setTestData("");
    }
  };

  useEffect(() => {
    setList(defaultData)
  }, [defaultData])

  const handleDelete = (id) => {
    setList((oldData) => oldData.filter((elem, index) => index !== id));
  };

  const handleSubmit = (e) => {
  
    e.preventDefault()
    let latestData = []
    list.map((itm) => (
      latestData.push(itm._id)
    ))

    doSubmitUrl({ clients: latestData ? latestData : data.id, id: data.id })
  }


  return (

    <SideBar>
      <Container fluid='lg'>
        <Row>
          <Col xs={12} sm={12} md={7} lg={7}>
            <div className="custom_card">
              <p className='h3 fw-bold'>Edit assigned clients</p>
              <hr />
              <div className='mt-4'>

                <Form>

                  <Form.Group as={Row} className="mb-2" controlId="formPlaintextEmail">
                    <Form.Label column sm="10">

                      <select onChange={(e) => setTestData(e.target.value)} className='form-control'>
                        <option key="0" value="0">select clients</option>
                        {
                          (alluserList.filter((x) => x.role !== "operational" && x.active === true)).map((itm) => {
                            return (
                              <option key={itm._id} value={itm._id} >{itm.username}</option>
                            )
                          })
                        }
                      </select>
                    </Form.Label>
                    <Col sm="2">
                      <Button type="button" variant='success' className="mt-2" onClick={handleTodoList}>Add</Button>
                    </Col>
                  </Form.Group>


                  <Form.Group className="mb-3 mt-4">
                    <br />
                    <div>

                      {(list).map((item, id) => (
                        <div key={id}>
                          <Alert key='primary' variant='primary'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <b>{item.username} </b>
                              <span onClick={() => handleDelete(id)}><TiDelete size={24} color='red' /></span>
                            </div>
                          </Alert>


                        </div>
                      ))}
                    </div>

                    <Button type="button" className='mt-4' onClick={handleSubmit}>Assign</Button>
                  </Form.Group>

                </Form>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SideBar>


  )
}

export default OperationalUserDetails