import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    conversionCount: 0,
    whatsappUrlData: [],
    whatsappUrlLabel: []
}

export const whatsappSlice = createSlice({
    name: 'count',
    initialState,
    reducers: {
        setConversionCount: (s, a) => {
            s.conversionCount = a.payload;
        },
        setWhatsapData: (s, a) => {
            s.whatsappUrlData = a.payload;
        },
        setWhatsppLabel: (s, a) => {
            s.whatsappUrlLabel = a.payload;
        },
        getWhasappData: (s, a) => {
            s.whatsappUrlLabel = a.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setConversionCount, setWhatsapData, setWhatsppLabel, getWhasappData } = whatsappSlice.actions

export default whatsappSlice.reducer