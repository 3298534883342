import { POOL, USER } from "../../pool";
import axiom from "../../utils/axiom";

export const getDetailsByShortCode = (shortCode) =>
  axiom.post(POOL.base + POOL.paths.getDetails, {
    shortCode,
  });

export const deleteDetailsByShortCode = (shortCode) =>
  axiom.post(POOL.base + POOL.paths.deleteDetails, {
    shortCode,
  });

export const updateClientDetails = (details) =>
  axiom.patch(USER.base + USER.paths.updateClientDetails, details);

export const getClickStatsListByShortCode = (shortCode) =>
  axiom.get(POOL.base + POOL.paths.getClickStatsByInitialShortCode + shortCode);