import { CONVERSION, USER } from "../../../pool";
import axiom from "../../../utils/axiom";

export const listOfClients = () => axiom.get(USER.base + USER.paths.listOfClients);

export const listOfAllUsers = () => axiom.get(USER.base + USER.paths.listOfAllUserList);

export const listOfclientsbyOperational = (clientIds) => axiom.post(USER.base + USER.paths.getclietsByOperational, clientIds);

export const addConversionCount = (count) => axiom.post(CONVERSION.base + CONVERSION.paths.createConversionCount, { count });

export const getTodayConversionCount = (id) => axiom.post(CONVERSION.base + CONVERSION.paths.getTodayConversionCount, { id });

export const deleteUserById = (id) => axiom.post(USER.base + USER.paths.deleteUser, { id });


