import React from "react"
import { toast } from "react-hot-toast";
import { FaCross } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import UserLoginData from "../../Auth/UserLoginData";
import { setConversionCount } from "../../Whatsapp/slice";
import { addConversionCount, getTodayConversionCount, listOfClients, listOfclientsbyOperational } from "./api";

export const useListHook = (clientIds, id) => {

  const dispatch = useDispatch();

  const { isLoggedIn } = UserLoginData()

  const [clientList, setClientList] = React.useState([]);
  const [clientListByOperational, setClientListByOperational] = React.useState([]);

  const setGetCount = React.useCallback((conversionCount = 0) => {
    dispatch(setConversionCount(conversionCount))
  }, [])

  //setConversionCount
  const getCount = useSelector((state) => state.data.conversionCount);

  React.useEffect(() => {
    (async () => {
      const [data, err] = await listOfClients();
      if (err) console.error(err);
      if (data) setClientList(data.response);
    })();

    return () => {
      setClientList([]);
    }
  }, [isLoggedIn])

  React.useEffect(() => {
    if (clientIds) {
      (async () => {
        const [data, err] = await listOfclientsbyOperational(clientIds);
        // if (err) console.error(err);
        if (data) setClientListByOperational(data.response);
      })();

      return () => {
        setClientListByOperational([]);
      }
    }
  }, [clientIds])

  const doSubmitConversion = React.useCallback((count) => {
    (async () => {
      const [data, err] = await addConversionCount(count);
      if (err)
        toast(err.response.data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      if (data) {
        toast("👏 Conversion count added successfully...! ", {
          duration: 3000,
          style: {
            backgroundColor: "#dde5b6",
            fontSize: 14,
          },
        });
        setGetCount(data.response.conversionCount)
      }
    })();
  }, []);


  React.useEffect(() => {
    (async () => {
      const [data, err] = await getTodayConversionCount(id);
      if (data.response.length > 0) setGetCount(data.status !== false ? data.response[0].conversions : 0);
    })();
  }, [getCount])

  return {
    clientList,
    clientListByOperational,
    doSubmitConversion,
    getCount
  }
}