import { useSelector } from "react-redux";
import { useRoutes } from "./hook";

const { default: routes_no_session } = require("../routes.nosession");
const { default: routes_session } = require("../routes.session");
const { default: route_client } = require('../routes.client')
const { default: route_operational } = require('../routes.operational')

const Routes = (isLoggedIn) => {

    if (isLoggedIn) {

        const Role = isLoggedIn.response.role

        if (Role === "admin") {
            return { routes: routes_session }
        } else if (Role === "operational") {
            return { routes: route_operational }
        } else if (Role === "client") {
            return { routes: route_client }
        } else {
            return { routes: routes_no_session }
        }

    } else {
        return { routes: routes_no_session }
    }
}

export default Routes;