import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ChangePasswordApiFromUrl } from "./api";
import { FaCross } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const UpdatePassword = () => {

    const navTo = React.useCallback((givenUrl) => { window.location.href = givenUrl }, [])
    const doChangePassword = React.useCallback((details) => {
        (async () => {
            const [data, err] = await ChangePasswordApiFromUrl(details);
            if (err)
                toast(err.response.data.message, {
                    duration: 2000,
                    icon: <FaCross />,
                    style: {
                        backgroundColor: "#ffabcd",
                        fontSize: 14,
                    },
                });
            if (data) {
                localStorage.removeItem('token')
                toast("Password updated successfully... 🦊 ", {
                    duration: 3000,
                    style: {
                        backgroundColor: "#dde5b6",
                        fontSize: 14,
                    },
                });
                setTimeout(() => navTo('/login'), 1000)
            }
        })();
    }, []);

    // useEffect(() => {
    //     const userLoginData = JSON.parse(localStorage.getItem('token'))
    //     setUserData(userLoginData.response)
    // }, [])

    return {
        doChangePassword
    };
};
