import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import SideBar from '../../components/sidebar/Sidebar'
import { useShortUrlListHook } from './hook';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';


const AllDataScreen = ({ listOfShortCode }) => {

    const navigate = useNavigate();

    const reDirectToLink = (short_code) => {
        const redirectUrl = "/list/view/" + short_code;
        navigate(redirectUrl);
    }

    const [page, setPage] = useState(0)
    const [sizePerPage, setSizePerPage] = useState(0)


    const columns = [
        {
            dataField: 'id',
            text: 'No',
            sort: true,
            headerStyle: () => {
                return { width: "5%", backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row, index) => {
                let current_pagenum = page;
                let total_records_per_page = sizePerPage;
                let row_index = (index + 1);
                let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
                return serial_num
            }

        },
        {
            dataField: 'short_code',
            text: 'Short Code',
            sort: true,
            headerStyle: () => {
                return { width: "12%", backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span onClick={() => { reDirectToLink(row.short_code) }} style={{ cursor: 'pointer' }}>
                        {row.short_code}
                    </span>
                );
            }
        },
        {
            dataField: 'url',
            text: 'URL',
            sort: true,
            headerStyle: () => {
                return { width: "38%", backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span>{(row.url).length > 25 ? row.url.substring(0, 25) + "..." : row.url}</span>
                )
            }
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            headerStyle: () => {
                return { width: "14%", backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span>{(row.title).length > 25 ? row.title.substring(0, 20) + "..." : row.title}</span>
                )
            }
        },
        {
            dataField: 'clientName',
            text: 'Client Name',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            }
        },
        {
            dataField: 'clicks_count',
            text: 'Clicks',
            sort: true,
            headerStyle: () => {
                return { width: "8%", backgroundColor: '#cfe2ff' };
            },
        },
        {
            dataField: 'createdAt',
            text: 'Created At',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (new Date(row.createdAt).toLocaleDateString('en-En', { year: 'numeric', month: 'long', day: 'numeric' }));
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: false,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            setPage(page)
            setSizePerPage(sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    return (
        <div>
            {/* <SideBar> */}
            <Container>
                <div className="mt-4">
                    <p className='h3 fw-bold'>List of Tiny Urls</p>
                    <hr />
                    <BootstrapTable keyField='id' data={listOfShortCode} columns={columns} defaultSorted={defaultSorted} pagination={pagination} />
                </div>
            </Container>
            {/* </SideBar> */}
        </div>
    )
}

export default AllDataScreen