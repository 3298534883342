import React, { useState } from 'react'
import { MdAssignmentTurnedIn } from 'react-icons/md';
import whatsapp_img from '../../../Images/whatsapp.png'
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { useListHook } from './hook';
import { useUpdateStatus } from '../Modal/EditUserDetails/hook';



const ClientTable = ({ alluserList, open, userRole }) => {

    const navigate = useNavigate()
    const goToDetails = (id) => {
        navigate(`/operational-user/${id}`)
    }

    const [userId, setUserId] = useState("")

    const { deleteUser } = useUpdateStatus()

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = (id) => {
        setUserId(id)
        setShow(true);
    }

    const handleSubmit = ()=>{
        deleteUser(userId)
        handleClose()
    }

    

    return (

        <>

            <Modal show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ fontSize: 18 }}>
                        Do you want to delete this user
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleSubmit}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <table className="table table-bordered mt-4" cellPadding={5}>
                <thead className='table-primary'>

                    <tr>
                        <th width="10%">No</th>
                        <th width="20%">User Name </th>
                        <th>Phone No</th>
                        <th>Role</th>
                        {
                            userRole === "client" &&
                            <th style={{ textAlign: 'center' }}>Whatsapp Integration</th>
                        }

                        {
                            userRole === "operational"
                            &&
                            <>
                                <th style={{ textAlign: 'center' }}>Assign Clients</th>
                                {/* <th style={{ textAlign: 'center' }}>View</th> */}
                            </>

                        }
                        <th style={{ textAlign: 'center' }}>Status</th>
                        {
                            <th style={{ textAlign: 'center' }}>Edit</th>
                        }

                        <th style={{ textAlign: 'center' }}>Delete</th>
                    </tr>

                </thead>
                <tbody >

                    {
                        alluserList !== null && alluserList.length > 0 ? alluserList.map((item, index) => {
                            const { _id, username, role, mobile, active, whatsapp_integration } = item
                            return (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{username}</td>
                                    <td>{mobile}</td>
                                    <td>{role}</td>
                                    {
                                        userRole === "client" &&
                                        <td align='center'>
                                            {
                                                whatsapp_integration
                                                    ?
                                                    <img src={whatsapp_img} alt='whatsapp_icon' style={{ width: 25, height: 25 }} />
                                                    :
                                                    "-"
                                            }
                                        </td>
                                    }

                                    {
                                        userRole === "operational"
                                        &&
                                        <>
                                            <td align='center' >
                                                {/* <MdAssignmentTurnedIn size={20} onClick={() => { handleShowAssignModel(_id) }} /> */}
                                                <MdAssignmentTurnedIn size={20} onClick={() => { goToDetails(_id) }} />
                                            </td>
                                            {/* <td align='center' >
                                            <label className='btn-edit' onClick={() => { handleShowOperationalUnderClients(_id) }}>
                                                View
                                            </label>
                                        </td> */}
                                        </>
                                    }
                                    <td align='center'>
                                        <label className={active ? 'btn-active' : 'btn-in-active'}>{active ? "Active" : 'Inactive'}</label>

                                    </td>
                                    {
                                        <td align='center'>
                                            <label className='btn-edit' onClick={() => { open(_id) }}>
                                                Edit
                                            </label>
                                        </td>
                                    }

                                    <td align='center'>
                                        <label className='btn-in-active' onClick={() => { handleShow(_id) }}>
                                            Delete
                                        </label>
                                    </td>
                                </tr>
                            )
                        })
                            :
                            <tr>
                                <th colSpan='5'><div title="Maybe data not found?">🦊 🦊 🦊 🦊 🦊 🦊 🦊 ⁉️ </div></th>

                            </tr>
                    }
                </tbody>
            </table>
        </>
    )
}

export default ClientTable