import React from "react";
import SideBar from "../../components/sidebar/Sidebar";
import { useCreateUrlHook } from "./hook";
import validator from 'validator'
import { Form } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import { useListHook } from "../Admin/List/hook";
import { useUpdateStatus } from "../Admin/Modal/ClientsAssign/hook";
import UserLoginData from "../Auth/UserLoginData";
import { toast } from "react-hot-toast";
import { FaCross } from "react-icons/fa";

export const CreateShortUrlScreenName = "create-short-url";

export const CreateShortUrlScreen = () => {

  const { isLoggedIn } = UserLoginData()

  const { doSubmitUrl, otherDetails, setOtherDetails, handleInputChange } = useCreateUrlHook();
  const { urlData } = otherDetails;

  const handleOnchangeInputs = (e) => {
    
    const value = e.target.value
    
    console.log("value", typeof(value))

    const name = clientList.find(x => x._id == value)
    setOtherDetails({ ...otherDetails, clientName: name ? name.username : "", clientId: value })
  }

  // showing all client list
  const { clientList } = useListHook();

  const { defaultData } = useUpdateStatus(isLoggedIn && isLoggedIn.response._id);

  const handleSubmitUrl = (e) => {
    e.preventDefault();

    if (validator.isURL(urlData)) {
      if (otherDetails.clientId !== "") {
        doSubmitUrl({
          url: urlData,
          title: otherDetails.title ? otherDetails.title : "No Title Added",
          clientId: otherDetails.clientId,
          clientName: otherDetails.clientName,
          description: otherDetails.description ? otherDetails.description : "No Description Provided",
          utm_source: (otherDetails.tinySource).split(','),
          utm_medium: (otherDetails.tinyMedium).split(','),
          utm_campaign: (otherDetails.tinyCampaign).split(','),
          utm_term: (otherDetails.tinyTerm).split(','),
          utm_content: (otherDetails.tinyContent).split(','),
          loginUserId: isLoggedIn && isLoggedIn.response._id
        });
      } else {
        toast("Please select client", {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      }

    } else {
      toast("Please enter valid url", {
        duration: 2000,
        icon: <FaCross />,
        style: {
          backgroundColor: "#ffabcd",
          fontSize: 14,
        },
      });
    }

  };

  return (
    <div>
      <SideBar>
        <div className="p-3">
          <div className="text-center py-5" style={{
            fontFamily: 'monospace',
            fontSize: '2.5em'
          }}>Create something 💧</div>
          <hr />
          <div className="container">
            <form>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <input
                      type="url"
                      value={urlData}
                      className="form-control p-3"
                      id="url_website"
                      onChange={handleInputChange}
                      name="urlData"
                      aria-describedby="urlHelp"
                      placeholder="https://facebook.com"
                    />
                  </div>
                  <div className="col-md-6 mt-4">
                    <input
                      type="text"
                      value={otherDetails?.title ?? ""}
                      name="title"
                      className="form-control p-3"
                      placeholder="Title"
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-6 mt-4">
                    {/* <Select
                      options={options}
                      className='mt-4'
                    /> */}
                    <Form.Select
                      name="clientName"
                      className="form-control"
                      onChange={handleOnchangeInputs}>
                      {/* <option  value={otherDetails?.clientId ?? ""}>{otherDetails?.clientName ?? ""}</option> */}
                      <option value="">Please select client</option>
                      {
                        (isLoggedIn && isLoggedIn.response.role !== "admin" ? defaultData : clientList).map((client) => {
                          return (
                            <option value={client._id} key={client._id}>{client.username}</option>
                          )
                        })
                      }
                    </Form.Select>
                  </div>

                  <div className="col-md-12 mt-4">
                    <textarea
                      name="description"
                      value={otherDetails?.description ?? ""}
                      className="form-control p-3"
                      placeholder="Sample Description about the url"
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* <Accordion className="mt-4">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Add UTMs to track web traffic in analytics tools</Accordion.Header>
                      <Accordion.Body>
                        <div className="row px-4 py-4">
                          <div className="col-md-6 mt-4">
                            <label className="form-label">Source</label>
                            <input
                              type="text"
                              name="tinySource"
                              value={otherDetails?.tinySource ?? ""}
                              className="form-control"
                              placeholder="e.g., facebook, twitter"
                              onChange={handleInputChange} />
                          </div>
                          <div className="col-md-6 mt-4">
                            <label className="form-label">Medium</label>
                            <input
                              type="text"
                              name="tinyMedium"
                              value={otherDetails?.tinyMedium ?? ""}
                              className="form-control"
                              placeholder="e.g., cpc, banner, email"
                              onChange={handleInputChange} />
                          </div>
                          <div className="col-md-6 mt-4">
                            <label className="form-label">Campaign</label>
                            <input
                              type="text"
                              name="tinyCampaign"
                              value={otherDetails?.tinyCampaign ?? ""}
                              className="form-control"
                              placeholder="e.g., swacch bharat, spring_sale"
                              onChange={handleInputChange} />
                          </div>
                          <div className="col-md-6 mt-4">
                            <label className="form-label">Term (optional)</label>
                            <input
                              type="text"
                              name="tinyTerm"
                              value={otherDetails?.tinyTerm ?? ""}
                              className="form-control"
                              placeholder="e.g., something"
                              onChange={handleInputChange} />
                          </div>
                          <div className="col-md-6 mt-4">
                            <label className="form-label">Content (optional)</label>
                            <input
                              type="text"
                              name="tinyContent"
                              value={otherDetails?.tinyContent ?? ""}
                              className="form-control"
                              placeholder="e.g., something else"
                              onChange={handleInputChange} />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}
                  <div className="col-md-12 mt-4">
                    <button type="button" className="btn btn-primary" onClick={handleSubmitUrl}>Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>


      </SideBar>
    </div>
  );
};
