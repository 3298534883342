import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { getUserDetails, updateUserStatus } from "./api";
import { FaCross } from "react-icons/fa";
import { deleteUserById, listOfAllUsers } from "../../List/api";
import { useDispatch, useSelector } from "react-redux";
import { updateUserList } from "../../../Users/userSlice";
import { setSingleUserData } from "./slice";

export const useUpdateStatus = (clientId) => {
  
  // get all user list
  const alluserList = useSelector(state => state.user.userList);

  //get single user details
  const userDetails = useSelector(state => state.singleUserData.singleUserData);

  const [reload, setReload] = useState(false)
  const dispatch = useDispatch();

  const setAllUserList = React.useCallback((newUserList) => {
    dispatch(updateUserList(newUserList))
  }, [])

  const setUserDetails = React.useCallback((user) => {
    dispatch(setSingleUserData(user))
  }, [clientId])

  const doSubmitUrl = React.useCallback((details) => {
    (async () => {
      const [data, err] = await updateUserStatus(details);
      if (err)
        toast(err.response.data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      if (data) {
        toast("👏 Record Updated Successfully...! 🦊 ", {
          duration: 3000,
          style: {
            backgroundColor: "#dde5b6",
            fontSize: 14,
          },
        });
        setReload(data.success)

      }
    })();
  }, []);


  React.useEffect(() => {
    (async () => {
      const [data, err] = await listOfAllUsers();
      if (err) console.error(err);
      if (data) setAllUserList(data.response);
    })();

    return () => {
      setAllUserList([]);
      setReload(false)
    }
  }, [reload])


  const deleteUser = (id) => {
    (async () => {
      const [data, err] = await deleteUserById(id);
      if (err) console.error(err);
      const setLatestUserData = alluserList.filter((x) => x._id !== id)
      if (data.success === true) {
        toast("🗑️ user deleted successfully...!", {
          duration: 2000,
          style: {
            backgroundColor: "#003049",
            color: "#fff",
            fontSize: 13,
          },
        });
        setAllUserList(setLatestUserData)
      }


    })();
  }

  return { reload, userDetails, setUserDetails, doSubmitUrl, alluserList, setAllUserList, deleteUser };
};
