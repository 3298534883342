import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useUpdateStatus } from './hook';
import validator from 'validator'
import { toast } from "react-hot-toast";
import { FaCross } from 'react-icons/fa';

const EditUserDetails = ({ openModal, closeModal, clientId }) => {

    const { userDetails, doSubmitUrl } = useUpdateStatus(clientId);

    const [whatsappIntegration, setWhatsappIntegration] = useState(userDetails?.whatsapp_integration)

    const [active, setActivate] = useState(userDetails?.active)

    const handleSubmit = () => {
        console.log("whatsappIntegration", whatsappIntegration)
        if (whatsappIntegration === "" || whatsappIntegration === "not_required") {
            doSubmitUrl({ whatsapp_integration: whatsappIntegration, active, id: clientId })
            closeModal();
        } else {
            if (validator.isURL(whatsappIntegration)) {
                doSubmitUrl({ whatsapp_integration: whatsappIntegration, active, id: clientId })
                closeModal();
            } else {
                toast("Please enter valid url", {
                    duration: 2000,
                    icon: <FaCross />,
                    style: {
                        backgroundColor: "#ffabcd",
                        fontSize: 14,
                    },
                });


            }
        }


    }

    return (
        <div>
            {
                userDetails !== undefined &&
                <Modal show={openModal} onHide={closeModal} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Client Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" defaultValue={userDetails.username} readOnly />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Phone no</Form.Label>
                                <Form.Control type="text" defaultValue={userDetails.mobile} readOnly />
                            </Form.Group>

                            {
                                userDetails.role === "client" &&

                                <Form.Group className="mb-3">
                                    <Form.Label>Whatsapp Url</Form.Label>
                                    <Form.Control name='whatsapp_integration' type="text" defaultValue={userDetails.whatsapp_integration} onChange={(e) => { setWhatsappIntegration(e.target.value) }} />
                                </Form.Group>
                            }

                            <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <select name='active' className='form-control' defaultValue={userDetails.active} onChange={(e) => { setActivate(e.target.value) }}>
                                    {/* <option value={false}>--Select--</option> */}
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

        </div >
    )
}

export default EditUserDetails