import React, { useEffect, useState } from 'react'
import SideBar from '../../components/sidebar/Sidebar'
import { Chart as chartjs } from 'chart.js/auto';
import { Doughnut, Line } from 'react-chartjs-2';
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useWhatsappHook } from './hook';
import UserLoginData from '../Auth/UserLoginData';
import { useListHook } from '../Admin/List/hook';
import { GrEdit } from "react-icons/gr";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


const WhatsappStatsScreen = () => {

    const { isLoggedIn } = UserLoginData()


    //get all client list
    const { clientList, clientListByOperational, doSubmitConversion, getCount } = useListHook(isLoggedIn.response.clients, isLoggedIn.response._id);

    const [whatsappUrl, setWhatsappUrl] = useState(null)

    const { data, label, dateCount } = useWhatsappHook(isLoggedIn.response.role === 'client' ? isLoggedIn.response.whatsapp_integration : whatsappUrl)

    // console.log("dateCount :", dateCount)
    const chartData = {
        labels: label ? label : [],
        datasets: [
            {
                label: "Click Stats",
                data: dateCount ? dateCount : [],
                fill: false,
                borderColor: "#000"
            }
        ]
    };

    // Conversions model open
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    const [conversionCount, setConversionCount] = useState()

    const handleSubmit = () => {
        doSubmitConversion({ conversionCount, userId: isLoggedIn.response._id })
        handleClose()
    }


    const [page, setPage] = useState(0)
    const [sizePerPage, setSizePerPage] = useState(0)

    const columns = [
        {
            dataField: 'contactNo',
            text: 'Mobile No',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span>{row.contactNo ? row.contactNo : row.contact_no}</span>
                )
            }

        },
        {
            dataField: 'district',
            text: 'District',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span>{row.district_name_en ? row.district_name_en : row.district}</span>
                )
            }
        },
        {
            dataField: 'taluka',
            text: 'Taluka',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span>{row.taluka_name_en ? row.taluka_name_en : row.taluka}</span>
                )
            }
        },
        {
            dataField: 'village',
            text: 'Village',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (
                    <span>{row.village_name_en ? row.village_name_en : row.village}</span>
                )
            }
        },
        {
            dataField: 'pincode',
            text: 'Pincode',
            sort: true,
            headerStyle: () => {
                return { width: "10%", backgroundColor: '#cfe2ff' };
            }
        },
        {
            dataField: 'state',
            text: 'State',
            sort: true,
            headerStyle: () => {
                return { backgroundColor: '#cfe2ff' };
            }
        },
        {
            dataField: 'createdAt',
            text: 'Date',
            sort: true,
            headerStyle: () => {
                return { width: "15%", backgroundColor: '#cfe2ff' };
            },
            formatter: (_, row) => {
                return (new Date(row.createdAt).toLocaleDateString('en-En', { year: 'numeric', month: 'long', day: 'numeric' }));
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'name',
        order: 'desc'
    }];


    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: false,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            setPage(page)
            setSizePerPage(sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page);
            console.log('sizePerPage', sizePerPage);
        }
    });

    // ---------

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('This will run after 1 second!')
            setLoading(false)
        }, 6000);
        return () => clearTimeout(timer);
    }, [loading])

    const count = data.reduce((accumulator, value) => {
        return { ...accumulator, [value.grampanchayat_name_en]: (accumulator[value.grampanchayat_name_en] || 0) + 1 };
    }, {});

    const doughnutLabel = []
    const doughnutCount = []
    const dColor = []

    const generateLabelandCount = count && Object.keys(count).map(x => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);

        return (
            doughnutLabel.push(x),
            doughnutCount.push(count[x]),
            dColor.push("rgb(" + r + "," + g + "," + b + ")")
        )
    })




    return (
        <div>
            <SideBar>

                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Conversions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Today Conversions</Form.Label>
                                <Form.Control
                                    onChange={(e) => setConversionCount(e.target.value)}
                                    type='number'
                                    // value={conversionCount}
                                    // onChange={(e) => {
                                    //     if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)) {
                                    //         setConversionCount(e.target.value);
                                    //     }
                                    // }}
                                    placeholder="Enter conversions" />
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Container>
                    {
                        isLoggedIn.response && isLoggedIn.response.role === 'client' && isLoggedIn.response.whatsapp_integration === "" && isLoggedIn.response.whatsapp_integration === null
                        &&
                        <Container fluid='lg'>
                            <Row>
                                <Col xs={12} sm={12} md={7} lg={7}>
                                    <div className="custom_card mt-4">
                                        <p className='h3 fw-thik'>
                                            Please contact to admin for whatsapp integration
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    }

                    {
                        isLoggedIn.response && isLoggedIn.response.whatsapp_integration !== "" && isLoggedIn.response.whatsapp_integration !== null
                            ?
                            <>
                                <p className='h3 fw-bold mt-4'>Whatsapp Stats</p>
                                <hr />

                                <Row >
                                    <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: 18 }}>
                                        <p className='h4 fw-bold'>Conversions : {getCount}
                                            {
                                                isLoggedIn.response && isLoggedIn.response.role !== "client"
                                                &&
                                                <>&nbsp;<GrEdit onClick={handleShow} size={16} /></>
                                            }

                                        </p>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6} style={{ marginTop: 18 }}>

                                        <span className=''>
                                            {
                                                isLoggedIn.response && isLoggedIn.response.role !== 'client'
                                                    ?
                                                    <p >
                                                        <div>
                                                            <select className='form-control' onChange={(e) => { setWhatsappUrl(e.target.value); setLoading(true) }}>
                                                                <option value={null}>Select</option>
                                                                {
                                                                    (isLoggedIn && isLoggedIn.response.role === 'operational' ? clientListByOperational : clientList).map((item, index) => {

                                                                        if (item.whatsapp_integration !== "") {
                                                                            return (
                                                                                <option key={index} value={item.whatsapp_integration}>{item.username}</option>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </p>
                                                    :
                                                    ""
                                            }

                                        </span>
                                    </Col>
                                    <hr />
                                    {/* <Col xs={12} sm={12} md={1} lg={1} className="p-4"></Col> */}
                                    <Col xs={12} sm={12} md={12} lg={12} className="p-4">
                                        {!loading ? dateCount.length > 0 ?
                                            ""
                                            :
                                            ""
                                            :
                                            <span style={{ display: 'flex', justifyContent: "center" }}>
                                                <Spinner animation="border" variant="primary" />
                                            </span>
                                        }
                                    </Col>
                                    <Col xs={12} sm={12} md={!count.undefined ? 8 : 12} lg={!count.undefined ? 8 : 12} className="p-4">
                                        {/* <div className="custom_card"> */}

                                        {!loading ? dateCount.length > 0 ?
                                            <>
                                                <p className='h6 fw-bold mt-4'>Total Clicks : {data.length}</p>
                                                <div className="mt-3">
                                                    <Line data={chartData} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {/* {
                                                    whatsappUrl === null ?
                                                        <p className='h3 fw-thik'>
                                                            Data not found
                                                        </p>
                                                        :
                                                        loader ?
                                                            <span style={{ display: 'flex', justifyContent: "center" }}>
                                                                <Spinner animation="border" variant="primary" />
                                                            </span>
                                                            :
                                                            <p className='h3 fw-thik'>
                                                                Data not found.
                                                            </p>
                                                } */}

                                                <p className='h3 fw-thik'>
                                                    Data not found.
                                                </p>

                                                {/* <p className='h3 fw-thik'>
                                                    Data not found.
                                                </p> */}

                                                {/* <p className='h3 fw-thik'>
                                                    Please select another client.
                                                </p> */}
                                            </>
                                            :
                                            // <span style={{ display: 'flex', justifyContent: "center" }}>
                                            //     <Spinner animation="border" variant="primary" />
                                            // </span>
                                            ""
                                        }


                                        {/* </div> */}
                                    </Col>
                                    <Col xs={12} sm={12} md={4} lg={4} className="p-4">
                                        {
                                            !loading ? dateCount.length > 0 ?
                                                !count.undefined &&
                                                <Doughnut
                                                    style={{ width: '100%' }}
                                                    data={{
                                                        labels: doughnutLabel,
                                                        datasets: [{
                                                            data: doughnutCount,
                                                            backgroundColor: dColor,
                                                            hoverOffset: 10
                                                        }]

                                                    }}
                                                />
                                                :
                                                ""
                                                :
                                                ""
                                        }

                                    </Col>
                                    {/* <Col xs={12} sm={12} md={1} lg={1} className="p-4"></Col> */}

                                    <Col xs={12} sm={12} md={12} lg={12} className="mt-3">
                                        {!loading ?
                                            dateCount.length > 0 && data.length > 0 ?
                                                <>
                                                    <BootstrapTable keyField='id' data={data} columns={columns} defaultSorted={defaultSorted} pagination={pagination} />
                                                </>
                                                :
                                                ""
                                            :
                                            ""
                                        }


                                    </Col>


                                </Row>
                            </>
                            :
                            <Container fluid='lg'>
                                <Row>
                                    <Col xs={12} sm={12} md={7} lg={7}>
                                        <div className="custom_card mt-4">
                                            <p className='h3 fw-thik'>
                                                Please contact to admin for whatsapp integration
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>


                    }
                </Container>

            </SideBar>
        </div>
    )
}

export default WhatsappStatsScreen