import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { getUserDetails, updateUserStatus } from "./api";
import { FaCross } from "react-icons/fa";
import { listOfAllUsers } from "../../List/api";

export const useUpdateStatus = (clientId) => {

  const [reload, setReload] = useState(false)
  const [userDetails, setUserDetails] = React.useState(undefined);
  const [defaultData, setdefaultData] = React.useState([])

  const [alluserList, setAllUserList] = React.useState([])

  const doSubmitUrl = React.useCallback((details) => {
    (async () => {
      const [data, err] = await updateUserStatus(details);
      if (err)
        toast(err.response.data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      if (data) {
        toast("👏 Record Updated Successfully...! 🦊 ", {
          duration: 3000,
          style: {
            backgroundColor: "#dde5b6",
            fontSize: 14,
          },
        });
        setReload(data.success)

        setUserDetails(existingDetails => {
          return {
            ...existingDetails,
            active: details.active
          }
        })
      }
    })();
  }, []);


  React.useEffect(() => {

    if (clientId !== "") {
      (async () => {
        const [data, err] = await getUserDetails(clientId);

        if (data) {
          setUserDetails(data.response);
          setdefaultData(data.defaultSelect)
        }
      })();
    }

  }, [clientId]);




  React.useEffect(() => {
    (async () => {
      const [data, err] = await listOfAllUsers();
      if (err) console.error(err);
      if (data) setAllUserList(data.response);
    })();

    return () => {
      setAllUserList([]);
    }
  }, [reload])


  return { reload, userDetails, setUserDetails, doSubmitUrl, alluserList, setAllUserList, defaultData };
};
