import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import ErrorPage from "./error-page";
import App from "./App";
import Routes from "./routes";
import store from './redux/store'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

let persistor = persistStore(store);

const { routes } = Routes();

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <RouterProvider router={router} />
        <footer className="text-muted align-items-center">
          <small
            style={{
              fontSize: 12,
              padding: 10,
              fontWeight: "bold",
              position: 'fixed',
              right: 0,
              bottom: 0,
            }}
          >
            Powered by Foxberry
          </small>
        </footer>
      </PersistGate>
    </Provider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
