import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list: []
}

export const listSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        setList: (s, a) => {
            s.list = a.payload;
        },
        updateList: (s, a) => {

        },
        deleteItemFromList: (s, a) => {
            
        }
    },
})

// Action creators are generated for each case reducer function
export const { setList } = listSlice.actions

export default listSlice.reducer