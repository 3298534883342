import ReactPaginate from "react-paginate";
import React, {
    useEffect,
    useState
} from "react";
import "./paginate.css"
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { GoGraph } from "react-icons/go";
import { FaExternalLinkAlt } from "react-icons/fa";


function Items({ currentItems, clientId }) {
    const shortUrlList = currentItems

    const navigate = useNavigate();



    const copyToClipboard = (url) => {
        const base = window.location.href;


        toast("👍 Copied to Clipboard! ", {
            duration: 3000,
            style: {
                backgroundColor: "#fdf0d5",
                fontSize: 13,
            },
        });


        if (base.indexOf("view") > -1) {
            var url_new_base = base.replace("list/view/", "");
            navigator.clipboard.writeText(url_new_base);
            return
        }

        navigator.clipboard.writeText(base.replace("list", "") + url);
    };

    const redirectTo = (shortUrlData) => {
        const shortCode = shortUrlData.short_code;
        const base = window.location.href;
        const redirectUrl = "view/" + shortCode;
        navigate(redirectUrl);
    };


    return (
        <div className="px-3 d-flex flex-row flex-wrap justify-content-start">
            {shortUrlList.map((shawty, indx) => {
                return (
                    <div
                        className="bg-dark card m-2 border-0 beautiful-shadow list-card"
                        style={{ maxWidth: 500, minWidth: 250 }}
                        onClick={() => copyToClipboard(shawty.short_code)}
                        key={indx}
                    >
                        <div className="card-body d-flex flex-row justify-content-between align-items-center">
                            <div>
                                <div
                                    style={{ fontSize: 12 }}
                                    className="card-text text-light"
                                >
                                    {shawty.url.length > 30
                                        ? shawty.url.substring(0, 26) + "..."
                                        : shawty.url}
                                </div>
                                <h6
                                    className="py-1 card-title text-warning link_copy"
                                    onClick={() => redirectTo(shawty)}
                                >
                                    <b>
                                        {shawty.short_code} <FaExternalLinkAlt size={12} />
                                    </b>
                                </h6>
                                <GoGraph size={12} color="white" />
                                <span className="text-white bg-dark" style={{ fontSize: 12, marginLeft: 5 }}>{shawty.clicks_count}</span>
                            </div>
                        </div>
                    </div>

                );
            })}
        </div>
    );
}

function PaginatedItems({ clientId, links, handlePageClick, pageCount, currentPage }) {

    return (
        <>
            <Items currentItems={links} clientId={clientId} />
            <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                forcePage={currentPage}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    );
}

export default (props) => (<div id="container">
    <PaginatedItems {...props} />
</div>)