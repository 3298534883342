import React, { useContext, useState } from 'react'
import { Col, Container, InputGroup, Row } from 'react-bootstrap'
import SideBar from '../../components/sidebar/Sidebar'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-hot-toast'
import { FaCross } from 'react-icons/fa'
import { UpdatePassword } from './hook';
import UserLoginData from '../Auth/UserLoginData';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';

const ProfileScreen = () => {

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const { doChangePassword } = UpdatePassword();

    const { isLoggedIn } = UserLoginData()


    const handleSubmit = () => {
        if (newPassword === confirmPassword) {
            doChangePassword({
                currentPassword,
                newPassword,
                username: isLoggedIn.response.username
            })
        } else {
            toast("Passwords does not match", {
                duration: 2000,
                icon: <FaCross />,
                style: {
                    backgroundColor: "#ffabcd",
                    fontSize: 14,
                },
            });
        }
    }

    const [viewCurrentPassword, setViewCurrentPassword] = useState(false)
    const [viewPassword, setViewPassword] = useState(false)
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false)



    return (

        <SideBar>
            <Container fluid='lg'>
                <Row>
                    <Col xs={12} sm={12} md={7} lg={7}>
                        <div className="custom_card">
                            <p className='h3 fw-bold'>Profile</p>
                            <hr />
                            <div className='mt-4'>
                                <p className='h5 fw-bold'>Security & authentication</p>
                                <p className='h6 fw-bold'>Change password</p>
                                <p>
                                    You will be required to login after changing your password
                                </p>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Current password</Form.Label>
                                        {/* <Form.Control type="password" onChange={(e) => { setCurrentPassword(e.target.value) }} /> */}

                                        <InputGroup className="mb-3">
                                            <Form.Control type={viewCurrentPassword ? "text" : "password"} onChange={(e) => { setCurrentPassword(e.target.value) }} />
                                            <InputGroup.Text id="basic-addon2" onClick={() => { setViewCurrentPassword(!viewCurrentPassword) }}>{viewCurrentPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</InputGroup.Text>
                                        </InputGroup>

                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New password</Form.Label>
                                        {/* <Form.Control type="password" onChange={(e) => { setNewPassword(e.target.value) }} /> */}
                                        <InputGroup className="mb-3">
                                            <Form.Control type={viewPassword ? "text" : "password"} onChange={(e) => { setNewPassword(e.target.value) }} />
                                            <InputGroup.Text id="basic-addon2" onClick={() => { setViewPassword(!viewPassword) }}>{viewPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm new password</Form.Label>
                                        {/* <Form.Control type="password" onChange={(e) => { setConfirmPassword(e.target.value) }} /> */}

                                        <InputGroup className="mb-3">
                                            <Form.Control type={viewConfirmPassword ? "text" : "password"} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                            <InputGroup.Text id="basic-addon2" onClick={() => { setViewConfirmPassword(!viewConfirmPassword) }}>{viewConfirmPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</InputGroup.Text>
                                        </InputGroup>

                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={handleSubmit}>
                                        Change password
                                    </Button>
                                </Form>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </SideBar>


    )
}

export default ProfileScreen