import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useUpdateUrlHook } from './hook';
import { toast } from 'react-hot-toast';
import { FaCross } from 'react-icons/fa';

const EditShortenDetails = ({ open, close, data }) => {

    const [shortCode, setShortCode] = useState(data.short_code)

    const { doSubmitUrl } = useUpdateUrlHook();

    const handleSubmit = () => {
        if (shortCode === "") {
            toast('Please enter short code', {
                duration: 2000,
                icon: <FaCross />,
                style: {
                    backgroundColor: "#ffabcd",
                    fontSize: 14,
                },
            });

        } else {
            doSubmitUrl({
                previos_short_code: data.short_code,
                short_code: shortCode
            })
            close();

        }

    }

    const handleClose = () => {
        close();
        setShortCode(window.location.pathname.replace("/list/view/", ""))
    }


    return (
        <>
            {
                data !== undefined ?
                    <Modal show={open} onHide={close} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Shorten Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Short Code</Form.Label>
                                    <Form.Control type="text" name="short_code" value={shortCode} onChange={(e) => { setShortCode(e.target.value.trim()) }} maxLength={32} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => { handleClose() }}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => { handleSubmit() }}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    ""
            }


        </>

    )
}

export default EditShortenDetails