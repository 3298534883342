import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/sidebar/Sidebar";
import { BsCalendarCheck, BsArrowReturnRight } from "react-icons/bs";
import { GoGraph } from "react-icons/go";
import { BiLock } from "react-icons/bi";
import { MdOutlineContentCopy, MdOutlineRestoreFromTrash } from "react-icons/md";
import { Row, Col, Container } from "react-bootstrap";
import { AiTwotoneEdit } from "react-icons/ai";
import EditShortenDetails from "../Admin/Modal/EditShorten/EditShortenDetails";
import EditClientDetails from '../Admin/Modal/EditClient/EditClientDetails'
import { useUpdateClientDetailsHook } from "../Admin/Modal/EditClient/hook";
import ClickStats from "./ClickStats";
import UserLoginData from "../Auth/UserLoginData";



export const ViewShortUrl = () => {

  // const { detailsOfUrl, deleteUrlDetails } = useDetailsHook(
  //   window.location.pathname.replace("/list/view/", "")
  // );

  const { detailsOfUrl, setUrlDetails, deleteUrlDetails } = useUpdateClientDetailsHook(window.location.pathname.replace("/list/view/", ""));

  const navigate = useNavigate();

  const redirectTo = () => {
    navigate("/list");
  };

  const [copyLink, setCopyLink] = useState(false);

  const copyToClipboard = (url) => {
    setCopyLink(true)
    const base = window.location.href;

    toast("👍 Copied to Clipboard! ", {
      duration: 3000,
      style: {
        backgroundColor: "#fdf0d5",
        fontSize: 13,
      },
    });

    if (base.indexOf("view") > -1) {
      var url_new_base = base.replace("list/view/", "");
      navigator.clipboard.writeText(url_new_base);
      return;
    }

    navigator.clipboard.writeText(base.replace("list", "") + url);
  };

  //open editmodel for shortcode update
  const [openEditModel, setOpenEditModel] = useState(false);

  const handleOpenModel = () => {
    setOpenEditModel(true);
  }

  const handleCloseModel = () => {
    setOpenEditModel(false)
  };

  //open editmodel for client name update
  const [openClientModel, setOpenClientModel] = useState(false);

  const handleOpenClientModel = () => {
    setOpenClientModel(true);
  }

  const handleCloseClientModel = () => {
    setOpenClientModel(false)
  };

  const handleClientUpdateAfterSubmit = (data) => {
    setUrlDetails(exd => {
      return {
        ...exd,
        ...data
      }
    })
  }

  //fetching user login details 
  const { isLoggedIn } = UserLoginData()

  return (
    <div>
      <SideBar>
        <div className="p-3 mb-3">
          <span className="px-3">
            <FaArrowLeft style={{ cursor: 'pointer' }} onClick={() => {
              navigate('/list');
            }} />
          </span>
          What's going on? 📊
        </div>
        <div className="px-3">

          {!detailsOfUrl ? (
            <div>🦊🦊🦊... </div>
          ) : (
            <>
              <EditShortenDetails data={detailsOfUrl} open={openEditModel} close={handleCloseModel} />

              <EditClientDetails data={detailsOfUrl} callback={handleClientUpdateAfterSubmit} open={openClientModel} close={handleCloseClientModel} />

              <Container fluid='lg'>
                
                <Row>

                  <Col xs={12} sm={12} md={6} lg={6} >
                    <div className="custom_card">
                      <div className="row">
                        <div className="col col-sm-10 col-md-6">
                          <label style={{ fontWeight: 600, fontSize: 22 }}>
                            {/* {detailsOfUrl.title} */}
                            {detailsOfUrl.title.length > 30
                                      ? detailsOfUrl.title.substring(0, 25) + "..."
                                      : detailsOfUrl.title}
                          </label><br />
                          <span>
                            {detailsOfUrl.clientName}
                            {
                              isLoggedIn.response.role !== "client" &&
                              <AiTwotoneEdit onClick={handleOpenClientModel} size={15} />
                            }

                          </span>

                        </div>
                        <div className="col col-sm-2 col-md-6 d-flex justify-content-end">
                          {
                            isLoggedIn.response.role !== "client" &&
                            <button
                              type="button"
                              className="btn btn-light h-50 mb-5"
                              onClick={() => { handleOpenModel(detailsOfUrl.short_code) }}>
                              <AiTwotoneEdit size={18} />{' '}Edit
                            </button>
                          }

                          {
                            isLoggedIn.response.role !== "client" &&
                            <button
                              type="button"
                              className="btn btn-light h-50 mb-5 ms-1"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "We will miss this little fox 🥲. Continue to delete 🔪🦊"
                                  )
                                ) {
                                  deleteUrlDetails(
                                    window.location.pathname.replace("/list/view/", "")
                                  );
                                  redirectTo();
                                }
                              }}>
                              <MdOutlineRestoreFromTrash size={18} />{' '}Delete
                            </button>
                          }

                        </div>
                      </div>

                      <p style={{ marginTop: 25 }}>
                        <BsCalendarCheck size={20} />{' '}
                        {new Date(detailsOfUrl.createdAt).toDateString('en-En', { month: '2-digit', day: 'numeric', year: 'numeric' })}
                      </p>
                      <GoGraph size={15} color="black" />
                      <span className="text-black bg-light" style={{ fontSize: 15, marginLeft: 10 }}>{detailsOfUrl.clicks_count} Total engagements</span>


                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6} >
                    <div className="custom_card">
                      <div className="row">
                        <div className="col col-sm-9 col-md-9">
                          <label
                            style={{ fontWeight: 600, fontSize: 22, cursor: "pointer", color: 'blue' }}
                            onClick={() => copyToClipboard(detailsOfUrl.short_code)}>
                            {window.location.href ? window.location.href.replace("list/view/", "") : ""}
                          </label>
                          <p className="mt-2">{detailsOfUrl.description}</p>



                        </div>
                        <div className="col col-sm-3 col-md-3 d-flex justify-content-end">
                          <button type="button" className="btn btn-light h-50 mb-5" onClick={() => { copyToClipboard(detailsOfUrl.short_code) }}>
                            {
                              copyLink ? <> <MdOutlineContentCopy />  Copied!</> : <><MdOutlineContentCopy /> Copy</>
                            }

                          </button>
                        </div>
                      </div>
                      <div style={{ marginTop: 25 }}>
                        <span className="text-black bg-light" style={{ fontSize: 15 }}>
                          {detailsOfUrl.clicks_count} Click
                        </span>
                      </div>

                      <div className="mt-3">
                        <BsArrowReturnRight />

                        <a href={detailsOfUrl.url} className="text-black" target="_blank" style={{ textDecoration: 'none' }}>
                          &nbsp;&nbsp;{detailsOfUrl.url.substring(0, 25) + "..."}
                        </a>

                        &nbsp;<BiLock size={18} /><span style={{ cursor: "not-allowed" }}>Redirect</span>
                      </div>


                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={12} className="mt-3">
                    <div className="custom_card">
                      <span className="text-black bg-light" style={{ fontSize: 15 }}>
                        <GoGraph size={15} color="black" /> {detailsOfUrl.clicks_count}
                      </span><br />
                      <b>TOTAL ENGAGEMENT</b>
                      <div className="mt-3">
                        <ClickStats initialShortCode={detailsOfUrl.initial_short_code} />
                      </div>
                    </div>
                  </Col>

                </Row>
              </Container>

            </>
          )}
        </div>
      </SideBar>
    </div>
  );
};
