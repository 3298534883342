// import { gapi } from 'gapi-script';
import React, { useEffect, useState } from 'react';
// import { GoogleLogin, GoogleLogout } from 'react-google-login';
// import { FcGoogle } from "react-icons/fc";
import { useCreateUrlHook } from "./hook";
import Logo from '../../Images/Logo.png'

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { FaUserAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';


const LoginScreen = () => {

  const navigate = useNavigate()

  // const clientId = "582720039712-v2knfr3lhnntlqu8kr8h7lo91nb9prak.apps.googleusercontent.com"

  // const [profile, setProfile] = useState([]);

  // const [showloginButton, setShowloginButton] = useState(true);
  // const [showlogoutButton, setShowlogoutButton] = useState(false);

  const [validated, setValidated] = useState(false);

  const [user, setUser] = useState({
    username: "",
    password: ""
  });

  const handleInputs = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value })
  }

  const { doSubmitUrl } = useCreateUrlHook();

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      setValidated(true);
      doSubmitUrl(user);

    }
  };

  // Google authentication code 
  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: '',
  //     });
  //   };
  //   gapi.load('client:auth2', initClient);
  // }, []);

  // const onSuccess = (res) => {
  //   setProfile(res.profileObj);
  //   setShowloginButton(false);
  //   setShowlogoutButton(true);
  // };

  // const onFailure = (err) => {
  //   console.log('Login Failed:', err);
  // };

  // const onSignoutSuccess = () => {
  //   setProfile();
  //   alert("You have been logged out successfully");
  //   console.clear();
  //   setShowloginButton(true);
  //   setShowlogoutButton(false);
  // };

  const isLoggedIn = useSelector(state => state.userData.isLoggedIn)

  useEffect(()=>{
    if(isLoggedIn){
      navigate('/list')
    }
  },[])

  const [viewPassword, setViewPassword] = useState(false)

  return (
    <div>

      <Container>
        <Row className='py-5'>
          <Col xs={12} md={4}></Col>
          <Col xs={12} md={4}>
            <div className="custom_card shadow-2-strong">
              <div className="text-center">
                <img src={Logo}
                  style={{ width: 80 }} alt="logo" />
                <p className="mt-1 mb-5 pb-1 fw-bold">We are The Foxberry Team</p>
                <hr />
              </div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Username</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        required
                        type="text"
                        name="username"
                        onChange={handleInputs}
                      />
                      <InputGroup.Text id="basic-addon2"><FaUserAlt /></InputGroup.Text>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group as={Col} md="12" className='mt-3'>
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        required
                        type={viewPassword ? "text" : "password"}
                        name='password'
                        onChange={handleInputs}
                      />
                      <InputGroup.Text id="basic-addon2" onClick={() => { setViewPassword(!viewPassword) }}>{viewPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Button type="submit" className='mt-3'>Login</Button>
              </Form>
            </div>

          </Col>
          <Col xs={12} md={4}></Col>
        </Row>
      </Container>


      {/* <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-4 col-lg-6 col-xl-5">
              <div className="custom_card shadow-2-strong">
                <form className="row g-3" >
                  <div className="text-center">
                    <img src={Logo}
                      style={{ width: 100 }} alt="logo" />
                    <h6 className="mt-1 mb-5 pb-1">We are The Foxberry Team</h6>
                    <hr />
                  </div>
                  <div className="col-12">
                    <label htmlFor="inputAddress" className="form-label">Username</label>
                    <input type="text" name="username" className="form-control" onChange={handleInputs} />
                  </div>
                  <div className="col-12 mb-5">
                    <label htmlFor="inputAddress2" className="form-label">Password</label>
                    <input type="password" name='password' className="form-control" onChange={handleInputs} />
                  </div>

                  <div className="col-md-6">
                    <button type="button" className="btn btn-primary w-100" onClick={handleSubmit}>Sign in</button>
                  </div>


                  <div className="col-md-6">
                      <div>
                        {showloginButton ?
                          <GoogleLogin
                            clientId={clientId}
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                            render={renderProps => (
                              <button className="btn btn-default bg-dark text-white w-100" onClick={renderProps.onClick}><FcGoogle /> SignIn with Google</button>
                            )}
                          /> : null}

                        {showlogoutButton ?
                          <GoogleLogout
                            clientId={clientId}
                            buttonText="Sign Out"
                            onLogoutSuccess={onSignoutSuccess}
                          >
                          </GoogleLogout> : null
                        }
                      </div>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div>

      </div>
    </div>
  )
}

export default LoginScreen