import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useListHook } from '../../List/hook';
import { useUpdateClientDetailsHook } from './hook';

const EditShortenDetails = ({ open, close, data, callback }) => {

    // showing all client list
    const { clientList } = useListHook();

    const [clientName, setClientName] = useState(data.clientName)
    const [clientId, setClientId] = useState(data.clientId)

    const handleOnchangeInputs = (e) => {
        const value = e.target.value
        const name = clientList.find(x => x._id == value)
        setClientName(name.username)
        setClientId(value)
    }

    const { doSubmitUrl } = useUpdateClientDetailsHook(data.short_code);

    const handleSubmit = () => {
        doSubmitUrl({
            short_code: data.short_code,
            clientName,
            clientId
        })

        callback({
            clientName,
            clientId
        })
    }


    return (
        <>
            {
                data !== undefined ?
                    <Modal show={open} onHide={close} backdrop="static" keyboard={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Client Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Short Code</Form.Label>
                                    <Form.Control type="text" name="short_code" value={shortCode} onChange={(e)=>{setShortCode(e.target.value)}} />
                                </Form.Group> */}

                                <Form.Group className="mb-3">
                                    <Form.Label>Client Name</Form.Label>
                                    <select name='clientName' value={clientId} className='form-control' onChange={handleOnchangeInputs}>
                                        <option>Select Client</option>
                                        {
                                            clientList.map((client) => {
                                                return (
                                                    <option key={client._id} value={client._id}>{client.username}</option>
                                                )
                                            })
                                        }
                                    </select>

                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => { close(); handleSubmit() }}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    ""
            }


        </>

    )
}

export default EditShortenDetails