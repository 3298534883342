import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWhatsappData } from "./api";
import { setWhatsapData, setWhatsppLabel } from "./slice";

export const useWhatsappHook = (whatsapp_url) => {

    const dispatch = useDispatch();

    const [data, setData] = useState([])

    const setLabel = React.useCallback((whatsappUrlLabel = []) => {
        dispatch(setWhatsppLabel(whatsappUrlLabel))
    }, [])

    const label = useSelector((state) => state.data.whatsappUrlLabel);

    const setDateCount = React.useCallback((whatsappUrlData = []) => {
        dispatch(setWhatsapData(whatsappUrlData))
    }, [])

    const dateCount = useSelector((state) => state.data.whatsappUrlData);


    React.useEffect(() => {

        console.log("whatsapp_url", whatsapp_url)
        if (whatsapp_url !== null) {

            (async () => {
                const [data] = await getWhatsappData(whatsapp_url);

                const whatsappApiData = data.response.length > 0 ? data.response : data.response.data

                if (whatsappApiData) {

                    setData(whatsappApiData)
                    // filtering the whatsappApiData for set to chart labels
                    const labelData = [];
                    whatsappApiData.forEach(element => {
                        labelData.push(new Date(element.createdAt).toLocaleDateString('en-GB', { month: '2-digit', day: 'numeric', year: 'numeric' }))
                    })

                    const withoutDuplicates = [...new Set(labelData)];

                    setLabel(withoutDuplicates)

                    // set the count of each date

                    var getDateCount = []
                    for (var i = 0; i < withoutDuplicates.length; i++) {

                        const countTypes = whatsappApiData.filter(item => (new Date(item.createdAt).toLocaleDateString('en-GB', { month: '2-digit', day: 'numeric', year: 'numeric' })) == withoutDuplicates[i]);

                        getDateCount.push(countTypes.length)
                    }

                    setDateCount(getDateCount)

                }
            })();
        } else {
            setDateCount([]);
            setLabel([]);
        }


        return () => {
            setDateCount([]);
            setLabel([]);
        }


    }, [whatsapp_url]);


    // const doSubmitWhatsappUrl = ()=>{
    //     dispatch(setWhatsapData(whatsappUrlData))
    // }




    return {
        data, label, dateCount
    }

}


