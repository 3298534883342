import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./404.css";
import UserLoginData from "./feature/Auth/UserLoginData";

export const WrongPlace = () => {

  const navigate = useNavigate()

  const { isLoggedIn } = UserLoginData()
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/list')
    }
  }, [])

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <div></div>
          <h1>404</h1>
        </div>
        <h2>Page not found</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
      </div>
    </div>
  );
};
