import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import SideBar from '../../../components/sidebar/Sidebar'
import { useCreateUrlHook } from './hook'

const CreateClientSCreen = () => {

    const [client, setClient] = useState({
        client_name: "",
        description: "",
        password: "",
        role: ""
    })

    const handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value
        setClient({ ...client, [name]: value })
    }

    const { doSubmitUrl } = useCreateUrlHook();

    const handleSubmit = (e) => {
        const { client_name, description, password, role } = client;
        e.preventDefault();
        doSubmitUrl({
            username: client_name,
            description: description,
            mobile: '',
            password,
            role
        });
    }

    return (
        <div>
            <SideBar>

                <Container fluid='lg'>
                    <Row className='mt-4'>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <div className="custom_card">
                                <p className='h3 fw-bold'>Create User</p>
                                <hr />

                                <div className='mt-4'>

                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control type="text" name='client_name' onChange={handleInputs}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" name='password' onChange={handleInputs}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Select name='role' aria-label="Default select example" onChange={handleInputs}>
                                                <option>---Select Role---</option>
                                                <option value="operational">Operational</option>
                                                <option value="client">Client</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control as="textarea" rows={3} name="description" onChange={handleInputs}/>
                                        </Form.Group>
                                        <Button variant="primary" type="button" onClick={handleSubmit}>
                                            Create
                                        </Button>
                                    </Form>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SideBar>

        </div>
    )
}

export default CreateClientSCreen