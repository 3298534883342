import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createApiFromUrl } from "./api";
import { FaCross } from "react-icons/fa";

export const useCreateUrlHook = () => {

    const navigate = useNavigate();

    const doSubmitUrl = React.useCallback((details) => {
        (async () => {
            const [data, err] = await createApiFromUrl(details);
            if (err)
                toast(err.response.data.message, {
                    duration: 2000,
                    icon: <FaCross />,
                    style: {
                        backgroundColor: "#ffabcd",
                        fontSize: 14,
                    },
                });
            if (data) {
                toast("User created successfully... 🦊 ", {
                    duration: 3000,
                    style: {
                        backgroundColor: "#dde5b6",
                        fontSize: 14,
                    },
                });

                // setTimeout(() => navigate('/client-list'), 3000)
            }
        })();
    }, []);


    return {
        doSubmitUrl
    };
};
