import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCross } from "react-icons/fa";
import { deleteDetailsByShortCode, getClickStatsListByShortCode, getDetailsByShortCode } from "../../../details/api";
import { updateClientDetails } from "./api";

export const useUpdateClientDetailsHook = (shortCode) => {

  const [reload, setReload] = useState(false)
  const [detailsOfUrl, setUrlDetails] = React.useState(undefined);
  const [clickStatsListByShortCode,setClickStatsListByShortCode] = React.useState(undefined)

  const doSubmitUrl = React.useCallback((details) => {
    (async () => {
      const [data, err] = await updateClientDetails(details);
      if (err)
        toast(err.response.data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      if (data) {
        toast("👏 Record Updated Successfully...! 🦊 ", {
          duration: 3000,
          style: {
            backgroundColor: "#dde5b6",
            fontSize: 14,
          },
        });
        setReload(data.success)
        setUrlDetails(existingDetails => {
          return {
            ...existingDetails,
            clientId: details.clientId,
            clientName: details.clientName
          }
        })
      }
    })();
  }, []);

  const deleteUrlDetails = React.useCallback((shortCode) => {
    (async () => {
      const [data, err] = await deleteDetailsByShortCode(shortCode);
      if (err) console.error(err);
      if (data)
        toast("🗑️ the link was removed", {
          duration: 2000,
          style: {
            backgroundColor: "#003049",
            color: "#fff",
            fontSize: 13,
          },
        });
    })();
  }, []);

  const fetchData = () => {
    (async () => {
      const [data, err] = await getDetailsByShortCode(shortCode);
      if (err) {
        toast("😖 this link may not exist", {
          duration: 2000,
          style: { backgroundColor: "#ffe6a7", fontSize: 13 },
        });
      }
      if (data) setUrlDetails(data.details);
    })();
    return setUrlDetails(undefined)
  }

  const getUrlbyInitShortCode = React.useCallback((initShortCode) => {
    (async () => {
      const [data, err] = await getClickStatsListByShortCode(initShortCode ?? shortCode);
      if (err) {
        toast("😖 this link may not exist", {
          duration: 2000,
          style: { backgroundColor: "#ffe6a7", fontSize: 13 },
        });
      }
      if (data) setClickStatsListByShortCode(data.response);
    })();
  }, [])

  React.useEffect(() => {
    fetchData()
  }, [reload]);

  return { getUrlbyInitShortCode, doSubmitUrl, detailsOfUrl, reload, setUrlDetails,deleteUrlDetails,clickStatsListByShortCode};
};
