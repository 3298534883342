import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { useUpdateStatus } from './hook';
import Select from 'react-select';

const ClientsAssign = ({ openModal, closeModal, clients, clientId }) => {


    const { doSubmitUrl, defaultData, handleChange } = useUpdateStatus(clientId);
    const [clientsData, setClientsData] = useState();

    React.useEffect(() => {
        setClientsData()
    }, [clientId])

    //redux 

    const handleSubmit = () => {
        doSubmitUrl({ clients: defaultData ? defaultData : clientId, id: clientId })
        closeModal()
    }


    return (
        <div>
            {
                clientsData && clientsData.data !== undefined && 
                <Modal show={openModal} onHide={closeModal} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Clients</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Clients</Form.Label>
                                <Select
                                    defaultValue={
                                        clientsData && clientsData.data !== undefined && clientsData.defaultSelect
                                        
                                    }
                                    isMulti
                                    name="clients"
                                    options={clients}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={handleChange}
                                />

                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            Assign
                        </Button>
                    </Modal.Footer>
                </Modal>


            }

        </div>
    )
}

export default ClientsAssign