import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCheck, FaCross } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { createApiFromUrl } from "./api";

export const useCreateUrlHook = () => {

  const [otherDetails, setOtherDetails] = React.useState({
    title: "",
    description: "",
    tinySource: "",
    tinyMedium: "",
    tinyCampaign: "",
    tinyTerm: "",
    tinyContent: "",
    clientName: "",
    clientId: "",
    urlData: ""
  });

  const navigate = useNavigate();

  const setUrlData = React.useCallback((newUrlData = "") => {
    setData('urlData', newUrlData)
  }, [])

  const setData = React.useCallback((key, value) => {
    setOtherDetails(details => {
      return {
        ...details,
        [key]: value
      }
    })
  }, [])

  const navTo = React.useCallback((givenUrl) => { window.location.href = givenUrl }, [])

  const doSubmitUrl = React.useCallback((details) => {
    (async () => {
      const [data, err] = await createApiFromUrl(details);
      if (err)
        toast(err.response.data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      if (data.account === 'inactive') {

        toast(data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
        localStorage.removeItem('token')
        setTimeout(() => navTo('/login'), 3000)
      } else {
        toast("👏 you made a new tinyfox! 🦊 ", {
          duration: 3000,
          style: {
            backgroundColor: "#dde5b6",
            fontSize: 14,
          },
        });
        setTimeout(() => navigate('/list/view/' + data.shortCode), 3000)
      }
    })();
  }, []);


  const handleInputChange = (e) => {
    const nameOfInput = e.target.name;
    const value = e.target.value;
    setData(nameOfInput, value);
  }

  return {
    doSubmitUrl,
    otherDetails,
    setOtherDetails,
    setUrlData,
    setData,
    handleInputChange
  };
};
