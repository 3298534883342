import React, { useState } from 'react'
import SideBar from '../../../components/sidebar/Sidebar'
import { useUpdateStatus } from '../Modal/EditUserDetails/hook';
import EditUserDetails from '../Modal/EditUserDetails/EditUserDetails';
import ClientsAssign from '../Modal/ClientsAssign/ClientsAssign';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OperationalUnderClientsList from '../Modal/OperationalUnderClients/OperationalUnderClientsList';
import AlluserTable from './AlluserTable'
import { Button, Spinner } from 'react-bootstrap';
import { getUserDetails } from '../Modal/EditUserDetails/api';
import { setSingleUserData } from '../Modal/EditUserDetails/slice';
import { useDispatch } from 'react-redux';

const ClientListScreen = () => {

    const { alluserList } = useUpdateStatus();

    const dispatch = useDispatch()

    const [show, setShow] = useState(false);
    const [clientId, setClientId] = useState("")

    const handleClose = () => setShow(false);

    const setUserDetails = React.useCallback((user) => {
        dispatch(setSingleUserData(user))
    }, [clientId])

    const handleShow = async (_id) => {
        setClientId(_id)
        const [data, err] = await getUserDetails(_id);

        if (data) {
            setUserDetails(data.response);
        }
        setShow(true)
    };

    // Clients assign model function

    const [showAssignModel, setShowAssignModel] = useState(false);


    const handleCloseAssignModel = () => setShowAssignModel(false);

    const handleShowAssignModel = (_id) => {
        setClientId(_id)
        setShowAssignModel(true)
    };

    // let options = (alluserList.filter((item) => item.role !== "operational")).map(function (user) {
    //     return { value: user._id, label: user.username };
    // })

    let options = alluserList;

    // operational under clients model function

    const [showOperationalUnderClients, setShowOperationalUnderClients] = useState(false);

    const handleCloseOperationalUnderClients = () => setShowOperationalUnderClients(false);

    const handleShowOperationalUnderClients = (_id) => {
        setClientId(_id)
        setShowOperationalUnderClients(true)
    };

    console.log("alluserList", alluserList)

    return (
        <div>
            <SideBar>

                <EditUserDetails openModal={show} closeModal={handleClose} clientId={clientId} />

                <ClientsAssign openModal={showAssignModel} closeModal={handleCloseAssignModel} clients={options} clientId={clientId} />

                <OperationalUnderClientsList openModal={showOperationalUnderClients} closeModal={handleCloseOperationalUnderClients} />

                <div className='container'>

                    {/* <div className="custom_card"> */}
                    <p className='h3 fw-bold'>UserList</p>
                    <hr />
                    <Tabs
                        defaultActiveKey="clients"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        fill
                    >
                        <Tab eventKey="clients" title="Clients" style={{ backgroundColor: 'white' }}>
                            <a href='/create-client' target='_blank'>
                                <Button type='button'>Create New Client</Button>
                            </a>
                            {alluserList.length > 0 ?
                                <AlluserTable
                                    alluserList={alluserList?.filter((item) => item.role === "client")}
                                    open={handleShow}
                                    userRole="client" />
                                :
                                <span style={{ display: 'flex', justifyContent: "center" }}>
                                    <Spinner animation="border" variant="primary" />
                                </span>
                            }


                        </Tab>

                        <Tab eventKey="operationals" title="Operationals" style={{ backgroundColor: 'white' }}>
                            <a href='/create-operational' target='_blank'>
                                <Button type='button'>Create New Operational</Button>
                            </a>
                            {alluserList.length > 0 ?
                                <AlluserTable
                                    alluserList={alluserList.filter((item) => item.role === "operational")}
                                    open={handleShow}
                                    userRole="operational"
                                    handleShowAssignModel={handleShowAssignModel}
                                    handleShowOperationalUnderClients={handleShowOperationalUnderClients}
                                />
                                :
                                <span style={{ display: 'flex', justifyContent: "center" }}>
                                    <Spinner animation="border" variant="primary" />
                                </span>
                            }

                        </Tab>

                    </Tabs>

                    {/* </div> */}


                </div>
            </SideBar>
        </div>
    )
}

export default ClientListScreen