import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createApiFromUrl } from "./api";
import { FaCross } from "react-icons/fa";
import { setIsloggedIn, setUserData } from "./slice";
import { useDispatch } from "react-redux";

export const useCreateUrlHook = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    // const navTo = React.useCallback((givenUrl) => { window.location.href = givenUrl }, [])

    const doSubmitUrl = React.useCallback((details) => {

        (async () => {
            const [data, err] = await createApiFromUrl(details);
            if (err)
                toast(err.response.data.message, {
                    duration: 2000,
                    icon: <FaCross />,
                    style: {
                        backgroundColor: "#ffabcd",
                        fontSize: 14,
                    },
                });
            if (data) {
                toast("✌️Logged in successfully... 🦊 ", {
                    duration: 3000,
                    style: {
                        backgroundColor: "#dde5b6",
                        fontSize: 14,
                    },
                });

                dispatch(setIsloggedIn(true))
                dispatch(setUserData(JSON.stringify(data)))

                if (data.response.role === "admin") {
                    // setTimeout(() => navTo('/create'), 1000)
                    navigate('/create')
                } else if (data.response.role === "operational") {
                    navigate('/list')
                } else if (data.response.role === "client") {
                    navigate('/list')
                } else {
                    navigate('/list')
                }



            }
        })();
    }, []);


    return {
        doSubmitUrl
    };
};
