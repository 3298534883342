import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaCross } from "react-icons/fa";
import { redirect, useNavigate } from "react-router-dom";
import { updateDetails } from "./api";

export const useUpdateUrlHook = () => {

  const navigate = useNavigate()

  const doSubmitUrl = React.useCallback((details) => {
    (async () => {
      const [data, err] = await updateDetails(details);
      if (err)
        toast(err.response.data.message, {
          duration: 2000,
          icon: <FaCross />,
          style: {
            backgroundColor: "#ffabcd",
            fontSize: 14,
          },
        });
      if (data) {
        toast("👏 Record Updated Successfully...! 🦊 ", {
          duration: 3000,
          style: {
            backgroundColor: "#dde5b6",
            fontSize: 14,
          },
        });
        navigate(`/list/view/${details.short_code}`)
        // setTimeout(() => navigate(`/list/view/${details.short_code}`), 3000)
      }
    })();
  }, []);



  return {
    doSubmitUrl,

  };
};
