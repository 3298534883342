import React from 'react'
import { Chart as chartjs } from 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { useUpdateClientDetailsHook } from '../Admin/Modal/EditClient/hook';
import { Col, Row } from 'react-bootstrap';

const ClickStats = ({ initialShortCode }) => {

  const { clickStatsListByShortCode, getUrlbyInitShortCode } = useUpdateClientDetailsHook(window.location.pathname.replace("/list/view/", ""))

  React.useEffect(() => {
    getUrlbyInitShortCode(initialShortCode);
  }, []);

  if (!clickStatsListByShortCode) return <div> Loading .. </div>;

  const data = {
    labels: getLabels(clickStatsListByShortCode),
    datasets: [
      {
        label: "Click Stats",
        data: getCount(clickStatsListByShortCode),
        fill: false,
        borderColor: "#000"
      }
    ]
  };



  function getLabels(data = [{ _id: "" }]) {

    const labels = [];
    data.forEach(element => {
      labels.push(new Date(element._id).toLocaleDateString('en-GB', { month: '2-digit', day: 'numeric', year: 'numeric' }))

    });
    return labels;
  }


  function getCount(data = [{ clicks: 0 }]) {
    const countData = [];
    data.forEach(element => {
      countData.push(element.clicks)
    });
    return countData;
  }

  return (
    <>
      {/* <pre>{JSON.stringify(clickStatsListByShortCode, null, 2)} </pre> */}
      <Row>
        <Col xs={12} sm={12} md={1} lg={1}></Col>
        <Col xs={12} sm={12} md={10} lg={10}>
          <Line data={data} />
        </Col>
        <Col xs={12} sm={12} md={1} lg={1}></Col>

        {/* <Col xs={12} sm={12} md={6} lg={6} className="mt-3">
          <div className="custom_card">
            <div className="mt-3">
              <Bar
                style={{ width: '100%' }}
                data={data} />
            </div>


          </div>
        </Col> */}

      </Row>




    </>

  )
}

export default ClickStats