import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import Routes from "../../routes";
import SidebarMenu from "./SidebarMenu";
import { HiOutlineLogout } from "react-icons/hi";
import { toast } from "react-hot-toast";
import FoxberryLogo from '../../Images/Foxberry_Transparent_Logo.png'
import { useRoutes } from "../../routes/hook";
import { useDispatch } from "react-redux";
import { setIsloggedIn } from "../../feature/Login/slice";


const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useRoutes();
  const { routes } = Routes(isLoggedIn);

  const navigate = useNavigate();

  const dispatch = useDispatch()

  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleLogout = () => {
    dispatch(setIsloggedIn(false))
    toast("✌️ User logout successfully... 🦊 ", {
      duration: 3000,
      style: {
        backgroundColor: "#dde5b6",
        fontSize: 14,
      },
    });
    navigate('/login')
  }

  return (
    <>
      <div className="main-container" >
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          style={{ position: 'fixed' }}
          className={`sidebar `}
        >
          <div className="top_section">
            {/* <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo1"
                >
                  TinyFox
                </motion.h1>
              )}
            </AnimatePresence> */}

            {/* <div className="bars">
              <FaBars onClick={toggle} />
            </div> */}
            <div>
              <span><img src={FoxberryLogo} alt="Logo" style={{ width: 30 }} /></span>
            </div>
          </div>
          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              if (route.hidden) return;

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="link"
                  style={{ textDecoration: 'none' }}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
            <span className="menu-icon" onClick={handleLogout}>
              <HiOutlineLogout size={20} />
            </span>
          </section>
        </motion.div>

        <main style={{ marginLeft: 30 }}>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
