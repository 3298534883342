import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userData: {},
    isLoggedIn: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (s, a) => {
            s.userData = JSON.parse(a.payload);
        },
        setIsloggedIn: (s, a) => {
            s.isLoggedIn = a.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUserData, setIsloggedIn } = userSlice.actions

export default userSlice.reducer