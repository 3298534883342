import { POOL } from "../../pool";
import axiom from "../../utils/axiom";

export const listShortUrlsApi = (clientId, role) =>
    axiom.post(POOL.base + POOL.paths.listShortUrls, {
        clientId, role
    });

export const listShortUrlsApiPO = (clientId, role, page = 0, offset = 10) =>
    axiom.post(POOL.base + POOL.paths.listShortUrls + `?page=${page}&offset=${offset}`, {
        clientId, role
    });
