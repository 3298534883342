import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const UserLoginData = () => {

    const isLoggedIn = useSelector((state) => {
        return state.userData.userData
    })

    return (
        { isLoggedIn }
    )
}

export default UserLoginData