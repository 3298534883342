import ErrorPage from "./error-page";
import { ListShortUrls } from "./feature/list/ListScreen";
import { WrongPlace } from "./wrong-place";
import { AiFillEdit, AiOutlineLogout } from "react-icons/ai";
import AllDataScreen from "./feature/list/AllDataScreen";
import { ViewShortUrl } from "./feature/details/DetailsScreen";
import { Redirector } from "./feature/redirector/Redirector";
import { CreateShortUrlScreen } from "./feature/create/CreateScreen";
import WhatsappStatsScreen from "./feature/Whatsapp/WhatsappStatsScreen";
import { BsWhatsapp } from "react-icons/bs";
import OperationalUserDetails from "./feature/Admin/List/OperationalUserDetails";
import { FiLink } from "react-icons/fi";
import { RiFileList3Fill } from "react-icons/ri";

const route_operational = [
  {
    path: "/create",
    element: <CreateShortUrlScreen />,
    errorElement: <ErrorPage />,
    name: "Create",
    icon: <AiFillEdit size={18}/>
  },
  {
    path: "/list",
    element: <ListShortUrls />,
    errorElement: <ErrorPage />,
    name: "List",
    icon: <FiLink size={18}/>
  },
  {
    hidden:true,
    path: "/lists",
    element: <AllDataScreen />,
    errorElement: <ErrorPage />,
    name: "List",
    icon: <RiFileList3Fill size={18}/>
  },
  {
    hidden: true,
    path: "/list/view/:short_code",
    element: <ViewShortUrl />,
    errorElement: <ErrorPage />,
    name: "List",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/:short_code",
    element: <Redirector />,
    errorElement: <ErrorPage />,
    name: "Redirector",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/",
    element: <WrongPlace />,
    errorElement: <ErrorPage />,
    name: "Error",
    icon: '🚫'
  },
  {
    path: "/whatsapp",
    element: <WhatsappStatsScreen />,
    errorElement: <ErrorPage />,
    name: "Whatsapp",
    icon: <BsWhatsapp />
  },
  {
    hidden: true,
    path: "/logout",
    element: <AiOutlineLogout />,
    errorElement: <ErrorPage />,
    name: "Logout",
    icon: '🚫'
  },
  {
    hidden: true,
    path: "/operational-user/:id",
    element: <OperationalUserDetails />,
    errorElement: <ErrorPage />,
    name: "Operational",
  },

]

export default route_operational;