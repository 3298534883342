import { AiFillEdit, AiOutlineLogout } from "react-icons/ai";
import ErrorPage from "./error-page";
import CreateClientSCreen from "./feature/Admin/Client/CreateClientSCreen";
import ClientListScreen from "./feature/Admin/List/ClientListScreen";
import PrivateRoute from "./feature/Auth/ProtectedRoute";
import { CreateShortUrlScreen } from "./feature/create/CreateScreen";
import { ViewShortUrl } from "./feature/details/DetailsScreen";
import AllDataScreen from "./feature/list/AllDataScreen";
import { ListShortUrls } from "./feature/list/ListScreen";
import ProfileScreen from "./feature/Profile/ProfileScreen";
import { Redirector } from "./feature/redirector/Redirector";
import { WrongPlace } from "./wrong-place";
import { BsCardList, BsWhatsapp } from "react-icons/bs";
import { FiLink } from "react-icons/fi";
import OperationalUserDetails from "./feature/Admin/List/OperationalUserDetails";
import { ImProfile } from "react-icons/im";
import { RiFileList3Fill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import CreateClientScreen from './feature/User/Client/CreateClientScreen'
import CreateOperationalScreen from './feature/User/Operational/CreateOperationalScreen'
import WhatsappStatsScreen from "./feature/Whatsapp/WhatsappStatsScreen";


const routes_session = [
  {
    path: "/create",
    element: <CreateShortUrlScreen />,
    errorElement: <ErrorPage />,
    name: "Create",
    icon: <AiFillEdit size={18}/>
  },
  {
    path: "/list",
    element: <ListShortUrls />,
    errorElement: <ErrorPage />,
    name: "List",
    icon: <FiLink size={18}/>
  },
  {
    hidden:true,
    path: "/lists",
    element: <AllDataScreen />,
    errorElement: <ErrorPage />,
    name: "List",
    icon: <RiFileList3Fill size={18}/>
  },
  {
    hidden: true,
    path: "/list/view/:short_code",
    element: <ViewShortUrl />,
    errorElement: <ErrorPage />,
    name: "List",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/:short_code",
    element: <Redirector />,
    errorElement: <ErrorPage />,
    name: "Redirector",
    icon: '😸'
  },
  {
    hidden: true,
    path: "/",
    element: <WrongPlace />,
    errorElement: <ErrorPage />,
    name: "Error",
    icon: '🚫'
  },

  // admin Routes 
  {
    hidden: true,
    path: "/create-client",
    element: <CreateClientScreen />,
    errorElement: <ErrorPage />,
    name: "Create Client",
    icon: '➕'
  },
  {
    hidden: true,
    path: "/create-operational",
    element: <CreateOperationalScreen />,
    errorElement: <ErrorPage />,
    name: "Create Operational",
    icon: '➕'
  },
  {
    path: "/user-list",
    element: <ClientListScreen />,
    errorElement: <ErrorPage />,
    name: "Create Client",
    icon: <ImProfile size={18}/>
  },
  {
    hidden: true,
    path: "/operational-user/:id",
    element: <OperationalUserDetails />,
    errorElement: <ErrorPage />,
    name: "Operational",
    icon:'➕'
  },
  {
    path: "/profile",
    element: <ProfileScreen />,
    errorElement: <ErrorPage />,
    name: "Profile",
    icon: <CgProfile size={18}/>
  },
  {
    path: "/whatsapp",
    element: <WhatsappStatsScreen />,
    errorElement: <ErrorPage />,
    name: "Whatsapp",
    icon:<BsWhatsapp size={18}/>
  }
]

export default routes_session;