import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userList: [],
}

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateUserList: (state, action) => {
      state.userList = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateUserList } = userSlice.actions

export default userSlice.reducer