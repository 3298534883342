import React, { useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { toast } from 'react-hot-toast'
import { FaCross } from 'react-icons/fa'
import { useCreateUrlHook } from '../Client/hook'
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const CreateOperationalScreen = () => {
    const [client, setClient] = useState({
        client_name: "",
        description: "",
        password: "",
        confirm_password: "",
        mobile: ""
    })

    const [viewPassword, setViewPassword] = useState(false)
    const [viewConfirmPassword, setViewConfirmPassword] = useState(false)

    const handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value
        setClient({ ...client, [name]: value })
    }

    const { doSubmitUrl } = useCreateUrlHook();

    const handleSubmit = (e) => {
        const { client_name, description, password, confirm_password, mobile } = client;
        e.preventDefault();
        if (confirm_password === password) {
            doSubmitUrl({
                username: client_name,
                description: description,
                mobile,
                password,
                role: 'operational',
                whatsapp_integration: "not_required"
            });
            setClient({
                client_name: "",
                description: "",
                password: "",
                confirm_password: "",
                mobile: ""
            })
        } else {

            toast("Passwords does not match", {
                duration: 2000,
                icon: <FaCross />,
                style: {
                    backgroundColor: "#ffabcd",
                    fontSize: 14,
                },
            });

        }

    }

    return (
        <div>
            <Container fluid='lg'>
                <Row >
                    <Col xs={0} sm={0} md={4} lg={4}></Col>
                    <Col xs={12} sm={12} md={4} lg={4} className='mt-4'>
                        <div className="custom_card">
                            <p className='h3 fw-thin'>Create Operational</p>
                            <hr />

                            <div className='mt-4'>

                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={client.client_name}
                                            name='client_name'
                                            // onChange={handleInputs}
                                            onChange={(e) => {
                                                if (!e.target.value.match(/[+@#$&%!~=^_:(){}\[\]|/*./\/g/\-/?<>,;`'""\\/]/) && e.target.value.trim()) {
                                                    handleInputs(e)
                                                }
                                                else if (e.target.value.length === 0) {
                                                    handleInputs(e)
                                                }
                                            }} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mobile no</Form.Label>
                                        <Form.Control type="number" value={client.mobile} name='mobile' onChange={handleInputs} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control type={viewPassword ? "text" : "password"} value={client.password} name='password' onChange={handleInputs} />
                                            <InputGroup.Text id="basic-addon2" onClick={() => { setViewPassword(!viewPassword) }}>{viewPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control type={viewConfirmPassword ? "text" : "password"} value={client.confirm_password} name='confirm_password' onChange={handleInputs} />
                                            <InputGroup.Text id="basic-addon2" onClick={() => { setViewConfirmPassword(!viewConfirmPassword) }}>{viewConfirmPassword ? <BsEyeFill /> : <BsEyeSlashFill />}</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control as="textarea" rows={3} value={client.description} name="description" onChange={handleInputs} />
                                    </Form.Group>
                                    <Button variant="primary" type="button" onClick={handleSubmit}>
                                        Create
                                    </Button>
                                </Form>

                            </div>
                        </div>
                    </Col>
                    <Col xs={0} sm={0} md={4} lg={4}></Col>
                </Row>
            </Container>

        </div>
    )
}

export default CreateOperationalScreen