import { configureStore, combineReducers } from '@reduxjs/toolkit'
import listReducer from '../feature/list/slice'
import userDataReducer from '../feature/Login/slice'
import userReducer from '../feature/Users/userSlice'
import whatsappSlice from '../feature/Whatsapp/slice'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import singleUserReducer from '../feature/Admin/Modal/EditUserDetails/slice'

const reducers = combineReducers({
  user: userReducer,
  list: listReducer,
  data: whatsappSlice,
  loginUser: userReducer,
  userData: userDataReducer,
  singleUserData:singleUserReducer
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;