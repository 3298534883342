import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  FaExternalLinkAlt, FaListAlt
} from "react-icons/fa";
import { useLoaderData, useNavigate } from "react-router-dom";
import SideBar from "../../components/sidebar/Sidebar";
import { useshortLinksHook } from "./hook";
import { GoGraph } from "react-icons/go";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import UserLoginData from "../Auth/UserLoginData";
import { useListHook } from "../Admin/List/hook";
import { useUpdateStatus } from "../Admin/Modal/ClientsAssign/hook";
import { BsFillGrid3X2GapFill } from "react-icons/bs";
import AllDataScreen from "./AllDataScreen";
import Select from 'react-select';
import PaginatedList from "./PaginatedList";
import axiom from "../../utils/axiom";
import { POOL } from "../../pool";
import { listShortUrlsApiPO } from "./api";
const itemsPerPage = 12;

export const defaultUrlListLoader = async () => {

  const [data, error] = await listShortUrlsApiPO("all", "admin", 0, itemsPerPage)

  if (error) throw error;
  if (data) return { shortLinks: data.urls, count: data.count }

}


export const ListShortUrls = () => {
  const { isLoggedIn } = UserLoginData()
  const { shortLinks, count } = useLoaderData();

  const [toggle, setToggle] = useState(false)
  const [loading, setLoading] = useState(true)
  const [links, setLinks] = useState(shortLinks)
  const [clientId, setclientId] = useState("all")
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  // const { shortLinks } = useshortLinksHook();
  const navigate = useNavigate();

  const copyToClipboard = (url) => {
    const base = window.location.href;


    toast("👍 Copied to Clipboard! ", {
      duration: 3000,
      style: {
        backgroundColor: "#fdf0d5",
        fontSize: 13,
      },
    });


    if (base.indexOf("view") > -1) {
      var url_new_base = base.replace("list/view/", "");
      navigator.clipboard.writeText(url_new_base);
      return
    }

    navigator.clipboard.writeText(base.replace("list", "") + url);
  };

  const redirectTo = (shortUrlData) => {
    const shortCode = shortUrlData.short_code;
    const base = window.location.href;
    const redirectUrl = "view/" + shortCode;
    navigate(redirectUrl);
  };


  // showing all client list
  const { clientList } = useListHook();

  const { defaultData } = useUpdateStatus(isLoggedIn && isLoggedIn.response._id);

  // const handleChange = (e) => {
  //   setclientId(e.target.value)
  // }

  var handleChange = (selectedOption) => {
    console.log(selectedOption);
    setclientId(selectedOption.value);
  };

  useEffect(() => {
    setItemOffset(0)
    setCurrentPage(0)
  }, [clientId])

  const handleGrid = () => {
    setToggle(true)
  }

  const handleList = () => {
    setToggle(false)
  }

  const listofClients = (isLoggedIn && isLoggedIn.response.role !== "admin" ? defaultData : clientList).map((client) => {
    return { value: client._id, label: client.username }
  })

  const options = [{ value: "all", label: "All" }, ...listofClients]


  const fetchItems = async (client = "all", role = "admin", page = 0, offset = itemsPerPage) => {
    setLoading(true)
    console.log('call fetch items')
    const [data, error] = await listShortUrlsApiPO(client, role, page, offset)
    if (error) throw error;
    if (data) {
      console.log('show list', data.urls)
      setPageCount(Math.ceil(data.count / itemsPerPage))
      setLinks(data.urls)
      setLoading(false);
    }

    
  }


  //#region Pagination Logic

  // We start with an empty list of items.
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.

    console.log(links)
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setPageCount(Math.ceil(count / itemsPerPage));
    fetchItems(clientId, isLoggedIn ? "admin" : "user", itemOffset / itemsPerPage, itemsPerPage)
  }, [itemOffset, itemsPerPage, clientId]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % count;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setItemOffset(newOffset);
    setCurrentPage(event.selected)
  };


  //#endregion Pagination Logic -End


  return (
    <div>
      <SideBar>
        <div className="p-3 mb-3">
          List of your tinyfoxes 🦊
          <br />
          <small style={{ fontSize: 10 }}>(Click to copy a little foxy)</small>
        </div>
        <Container>
          <Row>
            <Col xs={0} sm={0} md={9} lg={9} className='mb-2'>
              <span style={{ padding: "5px 10px 10px 10px", border: "1px solid #000", borderRadius: 5 }}>
                <BsFillGrid3X2GapFill color={toggle ? "black" : "blue"} size={18} onClick={handleList} /> | <FaListAlt color={toggle ? "blue" : "black"} size={18} onClick={handleGrid} />
              </span>

            </Col>
            <Col xs={12} sm={12} md={3} lg={3} className='mb-2'>
              {
                isLoggedIn.response.role !== "client"
                &&
                <>
                  {/* <select className='form-control' onChange={handleChange}>
                    <option value="all">All</option>
                    {
                      (isLoggedIn && isLoggedIn.response.role !== "admin" ? defaultData : clientList).map((client) => {
                        return (
                          <option value={client._id} key={client._id}>{client.username}</option>
                        )
                      })
                    }
                  </select> */}

                  <Select
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChange}
                    isSearchable={true}
                  />
                </>
              }

            </Col>
          </Row>
          <hr />

          {!shortLinks.length < 0 && <div title="Maybe try creating a link?">🦊 🦊 🦊 🦊 🦊 🦊 🦊 ⁉️ </div>}
          {loading && <div>Loading</div>}
          {(shortLinks.length > 0 && loading === false) && (<PaginatedList handlePageClick={handlePageClick} clientId={clientId} links={links} pageCount={pageCount} currentPage={currentPage} />)}
          {/* {
            shortLinks.length < 0 ?
              <>
                {
                  !toggle ?
                    <div className="px-3 d-flex flex-row flex-wrap justify-content-start">
                      {(shortLinks.filter(x => (clientId === "all" ? shortLinks : x.clientId === clientId))).map((shawty, indx) => {
                        return (
                          <>
                            <div
                              className="bg-dark card m-2 border-0 beautiful-shadow list-card"
                              style={{ maxWidth: 500, minWidth: 250 }}
                              onClick={() => copyToClipboard(shawty.short_code)}
                              key={indx}
                            >
                              <div className="card-body d-flex flex-row justify-content-between align-items-center">
                                <div>
                                  <div
                                    style={{ fontSize: 12 }}
                                    className="card-text text-light"
                                  >
                                    {shawty.url.length > 30
                                      ? shawty.url.substring(0, 26) + "..."
                                      : shawty.url}
                                  </div>
                                  <h6
                                    className="py-1 card-title text-warning link_copy"
                                    onClick={() => redirectTo(shawty)}
                                  >
                                    <b>
                                      {shawty.short_code} <FaExternalLinkAlt size={12} />
                                    </b>
                                  </h6>
                                  <GoGraph size={12} color="white" />
                                  <span className="text-white bg-dark" style={{ fontSize: 12, marginLeft: 5 }}>{shawty.clicks_count}</span>
                                </div>
                              </div>
                            </div>
                          </>

                        );
                      })}
                    </div>
                    :
                    <AllDataScreen listOfShortCode={shortLinks.filter(x => (clientId === "all" ? shortLinks : x.clientId === clientId))} />
                }

              </>

              :
              <span style={{ display: 'flex', justifyContent: "center" }}>
                <Spinner animation="border" variant="primary" />
              </span>
          } */}
        </Container>
      </SideBar>
    </div>
  );
};
